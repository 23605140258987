<!--This is the Orders page, with the list of all orders. -->
<template>
    <b-container fluid class="list-container py-4">
        <b-row class="mb-4">
            <b-col
                class="d-flex flex-column align-items-start justify-content-between flex-grow flex-sm-row align-items-sm-center"
            >
                <h1 class="text-primary-light mb-0">
                    {{ t('orders') }}
                </h1>
            </b-col>
        </b-row>

        <b-card>
            <b-row class="mb-2">
                <b-col xl="4" lg="5" class="h-80">
                    <h4 class="heavy mb-0">
                        {{ t('ordersList') }}
                    </h4>
                    <p class="gray-dark mb-0">
                        {{ t('ordersList_Desc1') }}
                    </p>
                    <p v-if="isDefaultSort" class="gray-dark">
                        {{ t('ordersList_Desc2') }}
                    </p>
                </b-col>

                <b-col
                    xl="8"
                    lg="7"
                    class="d-flex align-items-sm-start justify-content-between flex-column flex-sm-row"
                >
                    <b-form
                        @submit.prevent="searchChanged"
                        class="d-flex flex-column flex-lg-column flex-md-row flex-xl-row order-1"
                    >
                        <b-input-group class="col-lg-12 col-xl-8 px-0 mr-2 mb-2 mb-xl-0">
                            <b-input-group-prepend is-text>
                                <b-icon icon="search"></b-icon>
                            </b-input-group-prepend>
                            <b-form-input
                                type="search"
                                v-model="queryParams.search"
                                :placeholder="t('ordernumber')"
                                id="searchInput"
                            ></b-form-input>
                        </b-input-group>

                        <div class="h-100 d-flex justify-content-between">
                            <b-button type="submit" variant="primary"> {{ t('search') }} </b-button>
                        </div>
                    </b-form>

                    <div class="h-100 d-flex align-items-start order-0 order-sm-2 mb-2 mb-sm-0">
                        <b-button @click="notImplemented()" variant="secondary" class="mr-2">
                            {{ t('filters') }} <b-icon-filter class="ml-1" />
                        </b-button>
                        <b-button @click="notImplemented()" variant="secondary">
                            {{ t('export') }} <b-icon-box-arrow-right class="ml-1" />
                        </b-button>
                    </div>
                </b-col>
            </b-row>

            <OrderTable :queryParams="queryParams" @sort-changed="sortChanged" />

            <b-row>
                <b-col cols="2" offset="10">
                    <b-pagination
                        v-if="pageDetails.total"
                        @change="pageChange"
                        align="right"
                        size="sm"
                        :value="queryParams.page"
                        :total-rows="pageDetails.total"
                        :per-page="queryParams.perPage"
                    ></b-pagination>
                </b-col>
            </b-row>
        </b-card>
        <p class="font-weight-lighter mt-2">
            <small>{{ t('disclaimer') }} </small>
        </p>
    </b-container>
</template>

<script>
import {mapState, mapActions} from 'vuex';
import {merge, snakeCase} from 'lodash';
import OrderTable from './components/OrderTable.vue';

const status = 'ord,can';
const defaultSort = {
    sortOrder: 'desc',
    sortBy: 'order_date',
};
const defaultQueryParams = () => ({
    search: null,
    page: 1,
    perPage: 10,
    ...defaultSort,
});

export default {
    name: 'OrderList',
    components: {OrderTable},
    data() {
        return {
            queryParams: defaultQueryParams(),
        };
    },
    computed: {
        ...mapState({
            pageDetails: (state) => state.orders.details,
        }),
        isDefaultSort() {
            return (
                defaultSort.sortOrder === this.queryParams.sortOrder &&
                defaultSort.sortBy === this.queryParams.sortBy
            );
        },
    },
    methods: {
        ...mapActions('orders', ['clearList']),
        async searchChanged() {
            if (!this.queryParams.search) this.queryParams = defaultQueryParams();
            this.queryParams.page = 1;
            await this.refreshRecords();
        },
        async sortChanged(context) {
            if (!context.sortBy) return;
            this.queryParams.page = 1;
            this.queryParams.sortBy = snakeCase(context.sortBy);
            this.queryParams.sortOrder = context.sortDesc ? 'desc' : 'asc';
            await this.refreshRecords();
        },
        async pageChange(page) {
            this.queryParams.page = page;
            await this.refreshRecords();
        },
        async refreshRecords() {
            this.$router
                .replace({
                    query: this.queryParams,
                })
                .catch(() => {});
            await this.blockingRequest('orders/fetchList', {...this.queryParams, status});
        },
    },
    created() {
        this.clearList();
    },
    async mounted() {
        this.queryParams = merge({}, this.queryParams, this.$route.query);
        await this.refreshRecords();
    },
};
</script>
<style></style>
